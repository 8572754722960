import React from "react";

const getFlags = (width) => {
  return {
    spanish: (
      <img
        width={width}
        alt="ESPAÑA"
        src="http://purecatamphetamine.github.io/country-flag-icons/3x2/ES.svg"
      />
    ),
    french: (
      <img
        width={width}
        alt="FRANCIA"
        src="http://purecatamphetamine.github.io/country-flag-icons/3x2/FR.svg"
      />
    ),
    italian: (
      <img
        width={width}
        alt="ITALIA"
        src="http://purecatamphetamine.github.io/country-flag-icons/3x2/IT.svg"
      />
    ),
    german: (
      <img
        width={width}
        alt="ALEMANIA"
        src="http://purecatamphetamine.github.io/country-flag-icons/3x2/DE.svg"
      />
    ),
    belgium: (
      <img
        width={width}
        alt="BELGICA"
        src="http://purecatamphetamine.github.io/country-flag-icons/3x2/BE.svg"
      />
    ),
    netherlands: (
      <img
        width={width}
        alt="PAISES BAJOS"
        src="http://purecatamphetamine.github.io/country-flag-icons/3x2/NL.svg"
      />
    ),
    poland: (
      <img
        width={width}
        alt="POLONIA"
        src="http://purecatamphetamine.github.io/country-flag-icons/3x2/PL.svg"
      />
    ),
    sweden: (
      <img
        width={width}
        alt="SUECIA"
        src="http://purecatamphetamine.github.io/country-flag-icons/3x2/SE.svg"
      />
    ),
    ireland: (
      <img
        width={width}
        alt="Irlanda"
        src="http://purecatamphetamine.github.io/country-flag-icons/3x2/IE.svg"
      />
    ),
  };
};

export const Flag = ({ lang, width = 20 }) => {
  return <span>{getFlags(width)[lang]}</span>;
};
