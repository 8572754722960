export const MODE = process.env.REACT_APP_MODE;
export const API_URL = process.env.REACT_APP_API_URL;
export const CALENDAR_EMAIL = "athmageaio@gmail.com";
export const MARKETPLACES_IDS = [
  {
    country: "ES",
    lang: "es_ES",
    countryLabel: "España",
    marketplaceId: "A1RKKUPIHCS9HS",
    nativeLanguage: "español",
    key: "spanish",
    currency: "EUR",
    urlRootDomain: "es",
    fullUrlDomain: "amazon.es",
  },
  {
    country: "FR",
    lang: "fr_FR",
    countryLabel: "Francia",
    marketplaceId: "A13V1IB3VIYZZH",
    nativeLanguage: "francés",
    key: "french",
    currency: "EUR",
    urlRootDomain: "fr",
    fullUrlDomain: "amazon.fr",
  },
  {
    country: "DE",
    lang: "de_DE",
    countryLabel: "Alemania",
    marketplaceId: "A1PA6795UKMFR9",
    nativeLanguage: "alemán",
    key: "german",
    currency: "EUR",
    urlRootDomain: "de",
    fullUrlDomain: "amazon.de",
  },
  {
    country: "IT",
    lang: "it_IT",
    countryLabel: "Italia",
    marketplaceId: "APJ6JRA9NG5V4",
    nativeLanguage: "italiano",
    key: "italian",
    currency: "EUR",
    urlRootDomain: "it",
    fullUrlDomain: "amazon.it",
  },
  {
    country: "BE",
    lang: "fr_BE",
    countryLabel: "Bélgica",
    marketplaceId: "AMEN7PMS3EDWL",
    nativeLanguage: "neerlandés, francés, alemán",
    key: "belgium",
    currency: "EUR",
    urlRootDomain: "com.be",
    fullUrlDomain: "amazon.com.be",
  },
  {
    country: "NL",
    lang: "nl_NL",
    countryLabel: "Países Bajos",
    marketplaceId: "A1805IZSGTT6HS",
    nativeLanguage: "neerlandés",
    key: "netherlands",
    currency: "EUR",
    urlRootDomain: "nl",
    fullUrlDomain: "amazon.nl",
  },
  {
    country: "PL",
    lang: "pl_PL",
    countryLabel: "Polonia",
    marketplaceId: "A1C3SOZRARQ6R3",
    nativeLanguage: "polaco",
    key: "poland",
    currency: "PLN",
    urlRootDomain: "pl",
    fullUrlDomain: "amazon.pl",
  },
  {
    country: "SE",
    lang: "sv_SE",
    countryLabel: "Suecia",
    marketplaceId: "A2NODRKZP88ZB9",
    nativeLanguage: "sueco",
    key: "sweden",
    currency: "SEK",
    urlRootDomain: "se",
    fullUrlDomain: "amazon.se",
  },
  {
    country: "IE",
    lang: "en_IE",
    countryLabel: "Irlanda",
    marketplaceId: "A28R8C7NBKEWEA",
    nativeLanguage: "ingles",
    key: "ireland",
    currency: "EUR",
    urlRootDomain: "ie",
    fullUrlDomain: "amazon.ie",
  },
];
